<template>
  <div class="allocation-asset">
    <b-card>
      <div class="title-page-allcation-asset">
        <h4>{{isAllocation? 'Cập nhật': 'Tạo mới'}} cấp phát tài sản</h4>
      </div>
      <div class="main-content-page-allocation mt-2">
        <validation-observer ref="modelAllocation">
          <div class="form-input">
            <b-row>
              <b-col lg="3">
                <b-form-group>
                  <validation-provider #default="{ errors }" name="departmentId" rules="required">
                    <label class="font-size-label-custom font-weight-bold">Phòng ban <span class="text-danger">(*)</span></label>
                    <v-select disabled v-model="modelAllocation.organizationBranchId" @input="getListEmployeeByDepartment" label="organizationBranchName" :reduce="(department) => department.id" :options="listDepartment"></v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col lg="3">
                <b-form-group>
                  <validation-provider #default="{ errors }" name="employeeId" rules="required">
                    <label class="font-size-label-custom font-weight-bold">Nhân viên <span class="text-danger">(*)</span></label>
                    <v-select placeholder="Chọn nhân viên" v-model="modelAllocation.employeeId" :reduce="(idEmployee) => idEmployee.id" label="fullName" :options="listEmployee"></v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col lg="6">
                <b-form-group>
                  <label class="font-size-label-custom font-weight-bold">Tên tài sản <span class="text-danger">(*)</span></label>
                  <b-form-input readonly v-model="modelAllocation.deviceString"></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="3">
                <b-form-group>
                  <validation-provider #default="{ errors }" name="dateDevice" rules="required">
                    <label class="font-size-label-custom font-weight-bold">Ngày cấp <span class="text-danger">(*)</span></label>
                    <flat-pickr v-model="modelAllocation.assignDate"
                                class="form-control"
                                :config="configFlatpickr"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col lg="3">
                <b-form-group>
                  <validation-provider #default="{ errors }" name="assignPeople" rules="required">
                    <label class="font-size-label-custom font-weight-bold">Người cấp <span class="text-danger">(*)</span></label>
                    <b-form-input placeholder="Nhập tên người cấp" v-model="modelAllocation.assignBy"></b-form-input>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="12">
                <b-form-group>
                  <label class="font-size-label-custom font-weight-bold">Lý do</label>
                  <b-form-textarea v-model="modelAllocation.reason"></b-form-textarea>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="12">
                <b-form-group>
                  <label class="font-size-label-custom font-weight-bold">Thông tin thêm</label>
                  <b-form-textarea v-model="modelAllocation.metadata"></b-form-textarea>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
          <div class="acction-form">
            <b-button v-if="!isAllocation" class="mr-1" @click="addAllocation"
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-primary"
            >
              <feather-icon
                  icon="CheckIcon"
                  class="mr-50"
              />
              <span class="align-middle">Tạo mới</span>
            </b-button>
            <b-button v-if="isAllocation"  class="mr-1" @click="updateAllocation"
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-primary"
            >
              <feather-icon
                  icon="CheckIcon"
                  class="mr-50"
              />
              <span class="align-middle">Cập nhật</span>
            </b-button>
            <b-button v-if="!isAllocation" :to="{ name: 'manager-asset'}"
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-danger"
            >
              <feather-icon
                  icon="CornerDownLeftIcon"
                  class="mr-50"
              />
              <span class="align-middle">Quay lại</span>
            </b-button>
            <b-button v-if="isAllocation" :to="{ name: 'allocation-recall-asset'}"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-danger"
            >
              <feather-icon
                  icon="CornerDownLeftIcon"
                  class="mr-50"
              />
              <span class="align-middle">Quay lại</span>
            </b-button>
          </div>
        </validation-observer>
      </div>
    </b-card>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard,
  BButton,
  VBTooltip, BFormGroup, BRow, BCol, BFormInput, BFormTextarea,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import { Vietnamese } from 'flatpickr/dist/l10n/vn'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import useJwt from '@/auth/jwt/useJwt'
import * as moment from 'moment'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import { required } from '@validations'

export default {
  components: {
    BCard,
    BButton,
    BFormGroup,
    BRow,
    BCol,
    BFormInput,
    BFormTextarea,
    flatPickr,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      idAllocation: null,
      isAllocation: false,
      modelAllocation: {
        deviceString: '',
        assignBy: '',
        assignDate: '',
        deviceId: null,
        employeeId: null,
        employeeString: '',
        metadata: '',
        quantity: 1,
        deviceType: 0,
        reason: '',
        organizationBranchId: null,
        departmentId: null,
      },
      required,
      listDepartment: [],
      listEmployee: [],
      configFlatpickr: {
        wrap: true,
        altFormat: 'd/m/Y',
        altInput: true,
        locale: Vietnamese,
        allowInput: true,
      },
    }
  },
  methods: {
    getListDepartment() {
      this.$crm.get('organization-branch-school/find-all-department').then(res => {
        this.listDepartment = res.data
      })
    },
    getListEmployeeByDepartment() {
      if (this.modelAllocation.organizationBranchId === null) {
        this.listEmployee = []
      } else {
        this.$crm.post('employee-school/all', this.modelAllocation).then(res => {
          this.listEmployee = res.data
        })
      }
    },
    addAllocation() {
      this.$refs.modelAllocation.validate().then(success => {
        if (success) {
          if (this.modelAllocation.quantity) {
            this.modelAllocation.quantity = this.modelAllocation.quantity * 1
          }
          if (this.modelAllocation.assignDate) {
            this.modelAllocation.assignDate = moment(this.modelAllocation.assignDate).format('yyyy-MM-DDT00:00:00')
          }
          this.$crm.post('device/assign', [this.modelAllocation]).then(res => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Thành công!',
                icon: 'CheckIcon',
                variant: 'success',
                text: `Cấp phát tài sản ${this.modelAllocation.deviceString} thành công`,
              },
            })
            this.$router.push({
              name: 'allocation-recall-asset',
            })
          })
        }
      })
    },
    getDetailAllocationEmployee() {
      this.$crm.post(`device/find-assigned-device-by-id/${this.idAllocation}`).then(res => {
        this.modelAllocation = res.data[0]
        this.modelAllocation.organizationBranchId = parseInt(this.modelAllocation.organizationBranchId)
        this.modelAllocation.employeeId = parseInt(this.modelAllocation.employeeId)
        this.modelAllocation.deviceString = this.modelAllocation.type
        this.getListEmployeeByDepartment()
      })
    },
    updateAllocation() {
      this.$refs.modelAllocation.validate().then(success => {
        if (success) {
          this.$crm.post(`device/update-assigned-device/${this.idAllocation}`, this.modelAllocation).then(res => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Thành công!',
                icon: 'CheckIcon',
                variant: 'success',
                text: `Cập nhật cấp phát tài sản cho nhân viên ${this.modelAllocation.fullName} thành công`,
              },
            })
            this.$router.push({
              name: 'allocation-recall-asset',
            })
          })
        }
      })
    },
  },
  created() {
    const parameters = this.$route.query
    this.modelAllocation.deviceId = parameters.idAsset
    this.modelAllocation.deviceString = parameters.nameAsset
    this.idAllocation = parameters.idAllocation
    this.modelAllocation.organizationBranchId = parseInt(parameters.organizationBranchId)
    this.modelAllocation.departmentId = this.modelAllocation.organizationBranchId
    if (this.modelAllocation.organizationBranchId) {
      this.getListEmployeeByDepartment()
    }
    if (this.idAllocation) {
      this.isAllocation = true
      this.getDetailAllocationEmployee()
    }
    this.getListDepartment()
  },
}
</script>

<style lang="scss">
@import 'src/@core/scss/vue/libs/vue-select.scss';
@import 'src/@core/scss/vue/libs/vue-flatpicker.scss';
@import '../../@core/scss/custom/allocation-asset';
</style>
